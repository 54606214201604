
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import GridItem from './items/GridItem.vue'
  import { CourseInterface } from '@/interface/BaseInterface'
  import { groupBy } from 'lodash'

  @Component({
    components: { GridItem }
  })
  export default class CourseGridView extends Vue {
    @Prop({ required: true, type: Array }) data!: CourseInterface[]

    get listData() {
      return groupBy(this.data, 'format')
    }

    handleClick(data: CourseInterface) {
      this.$emit('onClick', data)
    }

    handleEdit(data: CourseInterface) {
      this.$emit('edit', data)
    }

    handleRemove(data: CourseInterface) {
      this.$emit('remove', data)
    }
  }
