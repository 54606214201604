
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { CourseInterface } from '@/interface/BaseInterface'

  @Component
  export default class GridItem extends Vue {
    @Prop({ required: true, type: Object }) data!: CourseInterface

    isConfilctClick = false

    get backgourdStyle() {
      if (!this.data.thumbnail || this.data.thumbnail === '') {
        const randomColor = this.data.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`
        return {
          backgroundColor: randomColor
        }
      } else {
        return {
          backgroundImage: `url(${this.data.thumbnail})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }
      }
    }

    handleClick() {
      if (this.isConfilctClick) {
        this.isConfilctClick = false
        return
      }
      this.$emit('onClick')
    }
  }
