
  import { Component, Vue } from 'vue-property-decorator'
  import CourseGridView from '../components/course/CourseGridView.vue'
  import CourseListView from '../components/course/CourseListView.vue'
  import getApi from '@/api'
  import CourseApi from '../api/CourseApi'
  import { CourseInterface, CategoryInterface } from '@/interface/BaseInterface'
  import CategoryApi from '../api/CategoryApi'
  import CourseCreate from '../components/create/CourseCreate.vue'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import { filter, includes, trim, upperCase } from 'lodash'

  const courseApi: CourseApi = getApi('course')
  const categoryApi: CategoryApi = getApi('category')

  const tBase = namespace('base')

  @Component({
    components: { CourseGridView, CourseListView, CourseCreate }
  })
  export default class Dashboard extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    type = 'grid'

    listCourse: CourseInterface[] = []

    categories: CategoryInterface[] = []

    search = ''

    get getListCourse() {
      return filter(this.listCourse, course => {
        return includes(upperCase(course.fullname), upperCase(trim(this.search)))
      })
    }

    getActiveColor(type: string) {
      if (this.type === type) {
        return '#008ee2'
      } else {
        return '#363636'
      }
    }

    handleChangeView() {
      if (this.type === 'list') {
        this.type = 'grid'
      } else {
        this.type = 'list'
      }
    }

    handleAdd() {
      this.setOpenPopup({
        module: 'createCourse',
        isOpen: true
      })
    }

    async handleDelete(data: CourseInterface) {
      try {
        courseApi.delete(data._id)
        this.listCourse = filter(this.listCourse, value => {
          return value._id !== data._id
        })
      } catch (error) {
        console.log(error)
      }
    }

    handleCourseClick(data: CourseInterface) {
      this.$router.push({ name: 'course', params: { id: data._id } })
    }

    handleSubmit() {
      this.setOpenPopup({
        module: 'createCourse',
        isOpen: false
      })
      this.init()
    }

    async init() {
      try {
        if (this.checkRole(['all'], 'COURSE')) {
          const result = await courseApi.list({
            perPage: 10000,
            page: 1
          })
          this.listCourse = result.items
        } else {
          const result = await courseApi.myCourse()
          this.listCourse = result
        }
        // const result = await courseApi.list({})
        // this.listCourse = result.items
        this.categories = await categoryApi.all({})
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
