
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { CourseInterface, CategoryInterface, CourseFormat } from '@/interface/BaseInterface'
  import { courseFormat } from '@/config'
  import getApi from '@/api'
  import CourseApi from '../../api/CourseApi'
  import { assign } from 'lodash'

  const courseApi: CourseApi = getApi('course')

  @Component
  export default class CourseCreate extends Vue {
    @Prop({ required: true, type: Array }) categories!: CategoryInterface[]

    data: CourseInterface = {
      _id: '',
      fullname: '',
      shortName: '',
      format: courseFormat[0],
      startDate: new Date(),
      endDate: new Date(),
      status: 1
    }

    format: CourseFormat[] = courseFormat

    reset() {
      this.data = {
        _id: '',
        fullname: '',
        shortName: '',
        format: courseFormat[0],
        startDate: new Date(),
        endDate: new Date(),
        status: 1
      }
    }

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      this.reset()
    }

    async handleSubmit() {
      try {
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            const result = assign(this.data)
            const data = await courseApi.create(result)
            this.$emit('submit', data)
            this.$message.success(this.$t('createSuccess') as string)
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
