
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { CourseInterface } from '@/interface/BaseInterface'

  @Component
  export default class CourseListView extends Vue {
    @Prop({ required: true, type: Array }) data!: CourseInterface[]

    handleClick() {
      this.$emit('onClick')
    }

    handleEdit(data: CourseInterface) {
      this.$emit('edit', data)
    }

    handleRemove(data: CourseInterface) {
      this.$emit('remove', data)
    }
  }
